<template>
    <div>
        Placeholder for Validation
    </div>
</template>

<script>
export default {
  name: 'import-home'
}
</script>
